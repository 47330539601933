import { useLoaderData } from "react-router-dom";
import { getContact } from "../contacts";

export async function loader({ params }) {
    const contact = await getContact(params.contactId);
    return { contact };
}

export default function Project() {
    const { contact } = useLoaderData();
    return (
        <>
            <div id="sidebar">
                <h1>Project page</h1>
                <div>
                    {contact.id} / {contact.first} {contact.last}
                </div>

            </div>
        </>
    );
}