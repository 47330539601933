import './App.css';
// import { AddProject } from './components/addProjectForm';
// import { AddTask } from './components/addTaskForm';
// import { ProjectsList } from './components/projectsList';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import Root, {loader as rootLoader, action as rootAction} from "./routes/root";
import ErrorPage from "./error-page";
import Project, {loader as contactLoader} from "./routes/project";
import EditContact, {action as editAction,} from "./routes/edit";
import Index from "./routes/index";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root/>,
        errorElement: <ErrorPage />,
        loader: rootLoader,
        action: rootAction,
        children: [
            {
                errorElement: <ErrorPage />,
                children: [
                    { index: true, element: <Index /> },
                    {
                        path: "contacts/:contactId",
                        element: <Project />,
                        loader: contactLoader,
                    },
                    {
                        path: "contacts/:contactId/edit",
                        element: <EditContact />,
                        loader: contactLoader,
                        action: editAction,
                    },
                ]
            }
        ]
    },

]);
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Todo App
        </p>
        {/*<hr/>*/}
        {/*<main>*/}
        {/*  <AddProject/>*/}
        {/*  <hr/>*/}
        {/*<AddTask/>*/}
        {/*<ProjectsList/>*/}
      {/*</main>*/}
          <RouterProvider router={router} />
      </header>

      
    </div>

  );
}

export default App;
